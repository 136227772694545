import React, { useState } from 'react';
import MainImage from '../../images/portfolio/CrispinGlover/HeroImage.jpg';
import Concept from '../../images/portfolio/CrispinGlover/Concept-1.jpg';
import Concept_2 from '../../images/portfolio/CrispinGlover/Concept-2.jpg';
import album from '../../images/portfolio/CrispinGlover/album.png';
import TShirt from '../../images/portfolio/CrispinGlover/tshirt.jpg';
import TShirt_2 from '../../images/portfolio/CrispinGlover/tshirt-2.jpg';
import PortfolioPage from '../../components/PortfolioPage';

const PortfolioCrispinGlover = () => {
    return <PortfolioPage img={MainImage} url="https://player.vimeo.com/video/651689150" title="Crispin Glover Records" description="I samarbeid med Crispin Glover Records, har vi produsert en dyster musikkvideo til sangen No Santa Clauses, av Patrik Fitzgerald feat. Lemur. Dette oppdraget utførte vi før julen 2021. Musikkvideoen tar mye inspirasjon fra Tim Burton og Edward Gorey. Sangen omhandler barn som ble brukt for å rense piper i England på 1800-tallet, hvor mange av barna omkom. Det var derfor viktig å formulere en dyster stemning i tegnestil, lys og bevegelse. (2021)">
         <div className="flex  mt-32 lg:px-0">
            <div className="max-w-sm">
              <img src={Concept}  />
              <img src={Concept_2} />
            </div>
          </div>
        
          <div className="flex flex-wrap justify-center mt-32 md:justify-between">
            <img src={album} className="mr-16" style={{height: "350px"}} />
            <img src={TShirt}  style={{height: "350px"}}/>
          </div>
          <img src={TShirt_2}  style={{height: "350px"}}/>
    </PortfolioPage>

}

export default PortfolioCrispinGlover;